import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { DefaultHeader } from "components/headers/default-header"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faPlus, faEquals } from "@fortawesome/free-solid-svg-icons"

const StoreCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle="数字x真实事件支持 | 简单的二维码运动"
          pagedesc="为了最先进的商业场所!为数字活动初学者提供的二维码活动。"
          pagepath={location.pathname}
        />
        <main className="store font-noto">
          <section className="store__fv">
            <div className="md:pt-6 pt-14 pb-20 md:px-24 px-2">
              <div className="text-center w-1/2 md:w-full mx-auto">
                <StaticImage
                  src="../../../images/product/store/top-subtitle.png"
                  alt="为了最先进的商业场所"
                  layout="constrained"
                />
              </div>
              <div className="w-10/12 mx-auto text-center md:block hidden md:mb-2">
                <StaticImage
                  src="../../../images/product/store/top_text_title.png"
                  alt="数字x真实事件支持。简单的二维码运"
                  layout="constrained"
                />
              </div>
              <div className="text-center md:hidden block mx-auto mb-1">
                <StaticImage
                  src="../../../images/product/store/top_text_title_sp.png"
                  alt="数字x真实事件支持。简单的二维码运"
                  layout="constrained"
                />
              </div>
              <div className="md:flex hidden md:gap-10 md:mb-6 circle-container mx-auto">
                <div>
                  <StaticImage
                    src="../../../images/product/store/feat_01.png"
                    alt="推荐给数字运动的初学者"
                    layout="constrained"
                  />
                </div>
                <div>
                  <StaticImage
                    src="../../../images/product/store/feat_02.png"
                    alt="最低价格为130,000日元起"
                    layout="constrained"
                  />
                </div>
                <div>
                  <StaticImage
                    src="../../../images/product/store/feat_03.png"
                    alt="从计划到印刷的一体化订购成为可能!!"
                    layout="constrained"
                  />
                </div>
              </div>
              <div className="block md:hidden text-center mb-6">
                <StaticImage
                  src="../../../images/product/store/sp_feat.png"
                  alt="推荐给数字运动的初学者・最低价格为130,000日元起・从计划到印刷的一体化订购成为可能"
                  layout="constrained"
                />
              </div>
              <div className="text-center">
                <a
                  href="https://hub.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer"
                  className="store__fv-cta-btn text-white inline-block text-xl md:text-4xl font-bold px-20 py-4 md:py-5"
                >
                  下载文件
                </a>
              </div>
            </div>
          </section>
          <section className="store__merit">
            <div className="container md:py-32 py-20 md:px-0 px-4">
              <h2 className="text-center md:mb-20 mb-8">
                <StaticImage
                  src="../../../images/product/store/title_merit.png"
                  alt="有助于采购宣传和通知"
                  layout="constrained"
                />
              </h2>
              <div className="bg-white px-4 py-6 md:py-12 md:px-20">
                <div className="md:flex md:gap-20 items-center">
                  <div className="md:w-2/6 w-1/2 mx-auto mb-6 md:mb-0">
                    <StaticImage
                      src="../../../images/product/store/phone.png"
                      alt=""
                      layout="constrained"
                    />
                  </div>
                  <div className="md:flex-1">
                    <div className="md:mb-14 mb-6">
                      <h3 className="font-bold md:text-4xl text-2xl mb-2">
                        将顾客送到商店
                      </h3>
                      <p className="md:text-xl font-bold">
                        组织一些活动，只有当你真正来到店里时才能参加，吸引更多的顾客!
                      </p>
                    </div>
                    <div className="md:mb-14 mb-6">
                      <h3 className="font-bold md:text-4xl text-2xl mb-2">
                        管理代码
                      </h3>
                      <p className="md:text-xl font-bold">
                        PARKLoT的二维码活动还可以发行和管理可变格式的二维码。
                      </p>
                    </div>
                    <div>
                      <h3 className="font-bold md:text-4xl text-2xl mb-2">
                        可嵌入传单中
                      </h3>
                      <p className="md:text-xl font-bold">
                        二维码可以嵌入到传单中。你只需一部智能手机就能参与该活动，无需经过下载应用程序或在社交网络上关注。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="store__custom md:py-32 py-20">
            <div className="container px-4 md:px-0">
              <h2 className="text-center md:mb-20 mb-8">
                <StaticImage
                  src="../../../images/product/store/title_custom.png"
                  alt="全方位的详细定制"
                  layout="constrained"
                />
              </h2>
              <ul className="flex w-full flex-wrap mx-auto md:mb-20 mb-8 text-center justify-center">
                <li className="item item--pink">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    发布和打印
                    <br className="md:block hidden" />
                    可变的QR码。
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_print.png"
                      alt="发布和打印可变的QR码"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    每张纸上可以打印不同的QR码。
                    <br />
                    从二维码发行到印刷的一致订购
                    <br className="hidden md:block" />
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    发布的二维码
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_qr.png"
                      alt="发布的二维码"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    参与比使用序列码更容易，
                    <br className="hidden md:block" />
                    可以用智能手机完成。
                  </p>
                </li>
                <li className="item item--yellow">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    彩票可以在
                    <br />
                    自己的LP内抽奖
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_lp.png"
                      alt="彩票可以在自己的LP内抽奖"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    抽奖活动也可以在该运动
                    <br className="hidden md:block" />
                    的LP网站内进行
                  </p>
                </li>
                <li className="item item--yellow">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    防止重复开奖
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_prevention.png"
                      alt="防止重复开奖"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    一旦发生抽奖，
                    <br className="hidden md:block" />
                    二维码可以被禁用
                  </p>
                </li>
                <li className="item item--pink">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    免费设置生产
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_lottery.png"
                      alt="免费设置生产"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    用于分期开奖的图像
                    <br className="hidden md:block" />
                    可以自由配置
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    自动抽奖功能
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_automatic.png"
                      alt="自动抽奖功能"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    自动抽出获奖者
                    <br className="hidden md:block" />
                    可以选择稍后抽签或立即抽签
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    DM发送功能
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_dm.png"
                      alt="DM发送功能"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    发送独特的礼品代码和URL
                    <br className="hidden md:block" />
                    作为中奖结果的通知
                  </p>
                </li>
                <li className="item item--yellow">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    定制订单
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_sort.png"
                      alt="定制订单"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    定制帖子的顺序，包括发布的日期
                    <br className="hidden md:block" />
                    和时间以及收到的喜欢的数量
                  </p>
                </li>
                <li className="item item--pink">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    定制彩票
                    <br />
                    屏幕的设计
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_design.png"
                      alt="定制彩票屏幕的设计"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    提供适合你自己的网站
                    <br className="hidden md:block" />
                    或竞选网站的设计
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    通过在LINE上注册
                    <br className="hidden md:block" />
                    成为朋友来加入我们
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <StaticImage
                      src="../../../images/product/store/ico_design.png"
                      alt="通过在LINE上注册成为朋友来加入我们"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    通过注册成为LINE好友并
                    <br className="hidden md:block" />
                    发送二维码来加入活动。
                  </p>
                </li>
              </ul>
              <div className="text-center md:mb-10 mb-8">
                <a
                  href="https://hub.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer"
                  className="store__custom-cta-btn inline-block rounded-xl md:py-6 py-4 md:px-24 w-full md:w-auto bg-white font-bold text-xl md:text-4xl"
                >
                  下载文件
                </a>
              </div>
              {/* <p className="md:text-xl font-bold text-center">
                数额见 "关税"。
              </p> */}
            </div>
          </section>
          <section className="store__howto">
            <div className="container md:py-32 py-20 md:px-0 px-4">
              <h2 className="text-center md:mb-24 mb-12">
                <StaticImage
                  src="../../../images/product/store/title_howto.png"
                  alt="你可以通过以下方式使用它"
                  layout="constrained"
                />
              </h2>
              <div className="case-container md:mb-28 mb-12 md:w-4/5">
                <div className="case-title">
                  <h3 className="z-10 text-xl md:text-3xl text-white inline-block text-center">
                    case1
                  </h3>
                </div>
                <div className="case-img mb-6 md:mb-0">
                  <StaticImage
                    src="../../../images/product/store/img_case01.png"
                    alt="组织一场只需要传单就能参加的运动"
                    layout="constrained"
                  />
                </div>
                <h4 className="font-bold text-2xl md:text-4xl md:mb-6 mb-2 pt-3">
                  组织一场只需要传
                  <br />
                  单就能参加的运动
                </h4>
                <p className="md:text-xl">
                  1.在商店里分发带有二维码的彩票
                  <br />
                  2.用你的智能手机阅读二维码
                  <br />
                  3.前往网站查看抽奖结果!!
                </p>
              </div>
              <div className="case-container md:w-4/5">
                <div className="case-title">
                  <h3 className="z-10 text-xl md:text-3xl text-white inline-block text-center">
                    case2
                  </h3>
                </div>
                <div className="case-img mb-6 md:mb-0">
                  <StaticImage
                    src="../../../images/product/store/img_case02.png"
                    alt="你可以随时抽签"
                    layout="constrained"
                  />
                </div>
                <h4 className="font-bold text-2xl md:text-4xl md:mb-6 mb-2 pt-3">
                  你可以随时抽签
                </h4>
                <p className="md:text-xl">
                  你不需要排队就可以参加抽奖活动!!
                  <br />
                  在咖啡馆时间或在回家的路上挑战彩票
                </p>
              </div>
            </div>
          </section>
          {/* <section className="store__plan">
            <div className="container md:py-32 py-20 md:px-0 px-4">
              <h2 className="text-center md:mb-24 mb-12">
                <StaticImage
                  src="../../../images/product/store/title_plan.png"
                  alt="关税计划"
                  layout="constrained"
                />
              </h2>
              <div className="bg-white md:py-12 md:px-14 py-6 px-3 mb-10">
                <h3 className="md:text-3xl text-2xl font-bold text-center mb-8">
                  基本费用
                </h3>
                <div className="flex items-center justify-center md:gap-6 gap-2 mb-4 md:mb-5">
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">初始费用</p>
                    <p className="font-bold text-xl md:text-4xl">80,000日元</p>
                  </div>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="text-2xl md:text-5xl text-text"
                  />
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">每月金额</p>
                    <p className="font-bold text-xl md:text-4xl">50,000日元</p>
                  </div>
                  <FontAwesomeIcon
                    icon={faEquals}
                    className="text-2xl md:text-5xl text-text"
                  />
                  <div className="price-box flex-1 price-box--emphasis">
                    <p className="text-sm mb-2 md:text-2xl">总金额</p>
                    <p className="font-bold text-xl md:text-4xl">130,000日元</p>
                  </div>
                </div>
                <p className="md:text-xl text-sm">
                  至少有20,000件起售
                  <br />
                  *金额因纸张数量而异。
                  <br />
                  *印刷费用：90,000日元起（考虑印刷费用的表述方式）
                  <br />
                  *交付时间约为一周
                </p>
              </div>
              <div className="bg-white md:py-12 md:px-14 py-6 px-3 md:mb-20 mb-8">
                <h3 className="md:text-3xl text-2xl font-bold text-center mb-8">
                  选择
                </h3>
                <div className="flex flex-wrap md:gap-10 gap-2 justify-center mb-2 md:mb-10">
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">收集赞</p>
                    <p className="font-bold text-xl md:text-4xl">30,000日元</p>
                  </div>
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">收集答复</p>
                    <p className="font-bold text-xl md:text-4xl">30,000日元</p>
                  </div>
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">设计定制</p>
                    <p className="font-bold text-xl md:text-4xl">30,000日元</p>
                  </div>
                </div>
                <div className="flex flex-wrap md:gap-10 gap-2 justify-center">
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">定制排序顺序</p>
                    <p className="font-bold text-xl md:text-4xl">30,000日元</p>
                  </div>
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">竞选LP创作机构</p>
                    <p className="font-bold text-xl md:text-4xl">
                      100,000日元起
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <a
                  href="https://hub.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer"
                  className="store__custom-cta-btn inline-block rounded-xl md:py-6 py-4 md:px-24 w-full md:w-auto bg-white font-bold text-xl md:text-4xl"
                >
                  下载文件
                </a>
              </div>
            </div>
          </section> */}
        </main>
      </Layout>
    </>
  )
}
export default StoreCampaign
